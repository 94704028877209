<template>
  <div class="px-7 py-10">
    <v-card>
      <v-card-title class="py-3" style="font-family: roboto slab"
        ><v-row justify="center">
          <v-col>
            <h4>STEP-1</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="8" class="pt-8 px-6">
            <div class="card-text">
              <p class="heading">Download Format</p>
              <p class="paragraph">
                First step is to download the csv file format. Fill up the csv
                file with the games data for all the required columns.
              </p>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" class="btn text-right px-2">
            <v-btn
              text
              style="border: 1px solid #38227a; border-radius: 10px"
              class="mr-3 text-capitalize"
              :loading="btnLoading"
              height="45"
              @click="getFormatBulkImport"
            >
              <span style="color: #38227a">Download Format</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-10">
      <v-card-title class="py-3">
        <v-row justify="center">
          <v-col>
            <h4>STEP-2</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" class="pt-8 px-6">
            <div class="card-text">
              <p class="heading">Upload Filled Excel File</p>
              <p class="paragraph">
                Final step is to upload the filled csv file which will import
                games data into the database.
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="px-6 pb-6">
           <label style="font-size: medium;">Upload File</label>
            <v-file-input
              v-model="file"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              outlined
              accept=".csv"
             
              color="#7254CF"
              dense
              clearable
            >
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              dark
              style="border-radius: 10px"
              class="mr-4 text-capitalize px-16"
              color="#38227A"
              height="45"
              :loading="submitLoading"
              @click="submitBulkUpload"
            >
              <span>Submit</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  // API_ADMIN_BULK_UPLOAD_DISTRICT,
  // API_ADMIN_BULK_UPLOAD_SCHOOL,
  // API_ADMIN_BULK_UPLOAD_TEACHER,
  API_BULK_IMPORT,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "GameBulkImport",
  data() {
    return {
      file: null,
      btnLoading: false,
      submitLoading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),
    getFormatBulkImport() {
      this.btnLoading = true;
      const successHandler = (res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.csv");
        document.body.appendChild(fileLink);

        fileLink.click();
        this.btnLoading = false;
      };
      const failureHandler = () => {
        this.btnLoading = false;
      };
      let formData = {};
      Axios.request_GET(
        API_BULK_IMPORT,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitBulkUpload() {
      if (this.file instanceof File) {
        this.submitLoading = true;
        const successHandler = (res) => {
          console.log(res);
          if (res.data.success) {
            this.showToast({
              message: res.data.message,
              color: "s",
            });
            this.file = {};
          } else {
            this.showToast({
              message:
                "This file has invalid fields, To find out download the file and try again.",
              color: "e",
            });
            setTimeout(() => {
              var fileURL = window.URL.createObjectURL(new Blob([res.data]));
              var fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "fieldError.csv");
              document.body.appendChild(fileLink);

              fileLink.click();
            }, 1000);
          }
          this.file = null;
          this.submitLoading = false;
        };
        const failureHandler = () => {
          this.submitLoading = false;
        };
        let formData = new FormData();

        formData.append("file", this.file);
        Axios.request_POST(
          API_BULK_IMPORT,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        //toggle
        this.showToast({
          message: "Select a File first",
          color: "w",
        });
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.v-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.card-text {
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.paragraph {
  margin-top: 5px;
  font-size: 17px;
}
.v-card__title {
  text-align: center;
  background-color: #d30024;
  color: white;
  min-height: 50px;
  font-family: Roboto Slab;
}
.v-input {
  background: #ffffff;
  border-radius: 6px;
}
.v-btn >>> span {
  font-size: 17px;
  font-weight: 600;
}
.btn.text-right.px-2.col-sm-3.col-12 {
  padding-top: 50px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .btn.text-right.px-2.col-sm-3.col-12 {
    padding-top: 0px;
  }
  .card-heading {
    position: absolute;
    left: 130px;
  }
  .card.v-card {
    max-height: 300px;
  }
  .btn {
    position: static;
  }
  .btn1 {
    position: static;
  }
  .v-input {
    margin-bottom: 20px;
  }
}
</style>
